<template>
  <b-sidebar
      no-close-on-backdrop
      shadow
      no-header
      right
      backdrop
      bg-variant="white"
      sidebar-class="sidebar-lg"
      id="capacity-new-record-sidebar"
      :backdrop-variant="'dark'"
      :visible="isCapacityNewSidebarActive">
    <template #default="{ hide }">

      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">{{ dataInfo.id === 0 ? 'Kapasite Bilgisi Ekle' : 'Kapasite Bilgisi Değiştir' }}</h5>
      </div>

      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">

          <validation-provider #default="validationContext" name="Ürün Grubu" rules="required">
            <b-form-group label="Ürün Grubu" :state="getValidationState(validationContext)">
              <v-select v-model="dataInfo.productGroupId" :options="productGroupOptions" :reduce="val => val.value" :clearable="true"/>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Period" rules="required">
            <b-form-group label="Period" :state="getValidationState(validationContext)">
              <v-select v-model="dataInfo.periodId" :options="periodOptions" :reduce="val => val.value" :clearable="true"/>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Miktar" rules="required">
            <b-form-group label="Miktar">
              <b-form-input trim placeholder="Miktar" v-model="dataInfo.amount" :state="getValidationState(validationContext)"/>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Birim" rules="required">
            <b-form-group label="Birim" :state="getValidationState(validationContext)">
              <v-select v-model="dataInfo.unitId" :options="unitOptions" :reduce="val => val.value" :clearable="true"/>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Durum" rules="required">
            <b-form-group label="Durum" label-for="isActive" :state="getValidationState(validationContext)">
              <v-select v-model="dataInfo.isActive" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div class="d-flex mt-2">
            <b-button variant="primary" class="mr-2" type="submit">Kaydet</b-button>
            <b-button type="button" variant="outline-secondary" @click="$emit('update:is-capacity-new-sidebar-active', false)">Vazgeç</b-button>
          </div>

        </b-form>
      </validation-observer>

      <Overlay :busy="busy"></Overlay>

    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {ref, watch} from '@vue/composition-api'
import {required} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import {statusOptions} from "@core/utils/filter"
import store from "@/store"
import {toastMessage} from "@core/utils/utils"
import {useToast} from "vue-toastification/composition"
import Overlay from "@/components/Overlay.vue"
import router from "@/router"

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,

    vSelect,
    Overlay,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  model: {
    prop: 'isCapacityNewSidebarActive',
    event: 'update:is-capacity-new-sidebar-active',
  },
  props: {
    isCapacityNewSidebarActive: {
      type: Boolean,
      required: true,
    },
    recordDataId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, {emit}) {
    const toast = useToast()
    const busy = ref(false)

    const blankRecordData = {
      id: 0,
      productGroupId: '',
      periodId: '',
      amount: null,
      unitId: '',
      isActive: true
    }
    const dataInfo = ref(JSON.parse(JSON.stringify(blankRecordData)))
    const resetRecordData = () => {
      dataInfo.value = JSON.parse(JSON.stringify(blankRecordData))
    }

    const productGroupOptions = ref([])
    store.dispatch('store-definition/definitionList', {groupKey: 'PRODUCT_GROUP'}).then(response => {
      response.data.data.forEach((value, index) => {
        productGroupOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && props.recordDataId === 0) {
          dataInfo.value.productGroupId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    })

    const periodOptions = ref([])
    store.dispatch('store-definition/definitionList', {groupKey: 'PERIOD'}).then(response => {
      response.data.data.forEach((value, index) => {
        periodOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && props.recordDataId === 0) {
          dataInfo.value.periodId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    })

    const unitOptions = ref([])
    store.dispatch('store-definition/definitionList', {groupKey: 'UNITS'}).then(response => {
      response.data.data.forEach((value, index) => {
        unitOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && props.recordDataId === 0) {
          dataInfo.value.unitId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    })

    const onSubmit = () => {
      busy.value = true
      store.dispatch('store/' + (dataInfo.value.id > 0 ? 'editCapacity' : 'addCapacity'), {customerId: router.currentRoute.params.id, dataInfo: dataInfo.value}).then(response => {
        toastMessage(toast, 'success', response.data.message)
        emit('update:is-capacity-new-sidebar-active', false)
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetRecordData)

    watch(() => props.isCapacityNewSidebarActive, (dataValue) => {
      if (dataValue) {
        if (props.recordDataId === 0) {
          resetForm()
        } else {
          busy.value = true
          store.dispatch('store/fetchCapacity', {customerId: router.currentRoute.params.id, id: props.recordDataId}).then(response => {
            dataInfo.value = response.data.data
          }).catch(error => {
            toastMessage(toast, 'danger', error.response.data.message)
            emit('update:is-capacity-new-sidebar-active', false)
          }).finally(message => {
            busy.value = false
          })
        }
      } else {
        resetForm()
      }
    })

    return {
      busy,
      dataInfo,
      refFormObserver,
      productGroupOptions,
      periodOptions,
      unitOptions,

      statusOptions,

      getValidationState,
      resetForm,
      onSubmit,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
#capacity-new-record-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
