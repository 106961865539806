<template>
  <b-sidebar
      no-close-on-backdrop
      shadow
      no-header
      right
      backdrop
      bg-variant="white"
      sidebar-class="sidebar-lg"
      id="contact-new-record-sidebar"
      :backdrop-variant="'dark'"
      :visible="isContactNewSidebarActive">
    <template #default="{ hide }">

      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">{{ dataInfo.id === 0 ? 'İlgili Kişi Ekle' : 'İlgili Kişi Değiştir' }}</h5>
      </div>

      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">

          <validation-provider #default="validationContext" name="İsim" rules="required">
            <b-form-group label="İsim">
              <b-form-input trim placeholder="İsim" v-model="dataInfo.name" :state="getValidationState(validationContext)"/>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Soyisim" rules="required">
            <b-form-group label="Soyisim">
              <b-form-input trim placeholder="Soyisim" v-model="dataInfo.lastName" :state="getValidationState(validationContext)"/>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Cinsiyet" rules="required">
            <b-form-group label="Cinsiyet" :state="getValidationState(validationContext)">
              <v-select v-model="dataInfo.gender" :options="genderOptions" :reduce="val => val.value" :clearable="true"/>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Departman">
            <b-form-group label="Departman">
              <b-form-input trim placeholder="Departman" v-model="dataInfo.department" :state="getValidationState(validationContext)"/>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Ünvan">
            <b-form-group label="Ünvan">
              <b-form-input trim placeholder="Ünvan" v-model="dataInfo.title" :state="getValidationState(validationContext)"/>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Email" rules="email">
            <b-form-group label="Email">
              <b-form-input trim placeholder="Email" v-model="dataInfo.email" :state="getValidationState(validationContext)"/>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Telefon">
            <b-form-group label="Telefon">
              <b-form-input trim placeholder="Telefon" v-model="dataInfo.phone" :state="getValidationState(validationContext)"/>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Telefon 2">
            <b-form-group label="Telefon 2">
              <b-form-input trim placeholder="Telefon 2" v-model="dataInfo.phone2" :state="getValidationState(validationContext)"/>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Doğum Tarihi">
            <b-form-group label="Doğum Tarihi" :state="getValidationState(validationContext)">
              <flat-pickr v-model="dataInfo.birthDate" class="form-control" :config="{altInput: true, altFormat: 'd.m.Y', dateFormat: 'Y-m-d', locale: { firstDayOfWeek: 1}, weekStart: 1}"/>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Not">
            <b-form-group label="Not">
              <b-form-textarea trim placeholder="Not" v-model="dataInfo.notes" :state="getValidationState(validationContext)"/>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Durum" rules="required">
            <b-form-group label="Durum" label-for="isActive" :state="getValidationState(validationContext)">
              <v-select v-model="dataInfo.isActive" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div class="d-flex mt-2">
            <b-button variant="primary" class="mr-2" type="submit">Kaydet</b-button>
            <b-button type="button" variant="outline-secondary" @click="$emit('update:is-contact-new-sidebar-active', false)">Vazgeç</b-button>
          </div>

        </b-form>
      </validation-observer>

      <Overlay :busy="busy"></Overlay>

    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {ref, watch} from '@vue/composition-api'
import {required} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import {statusOptions} from "@core/utils/filter"
import store from "@/store";
import {toastMessage} from "@core/utils/utils";
import {useToast} from "vue-toastification/composition";
import Overlay from "@/components/Overlay.vue";
import router from "@/router";
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BFormTextarea,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    flatPickr,

    vSelect,
    Overlay,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  model: {
    prop: 'isContactNewSidebarActive',
    event: 'update:is-contact-new-sidebar-active',
  },
  props: {
    isContactNewSidebarActive: {
      type: Boolean,
      required: true,
    },
    recordDataId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, {emit}) {
    const toast = useToast()
    const busy = ref(false)

    const blankRecordData = {
      id: 0,
      name: '',
      lastName: '',
      department: '',
      title: '',
      email: '',
      phone: '',
      phone2: '',
      birthDate: null,
      gender: '',
      notes: '',
      isActive: true,
    }
    const dataInfo = ref(JSON.parse(JSON.stringify(blankRecordData)))
    const resetRecordData = () => {
      dataInfo.value = JSON.parse(JSON.stringify(blankRecordData))
    }

    const genderOptions = ref([])
    store.dispatch('store-definition/definitionList', {groupKey: 'GENDER'}).then(response => {
      response.data.data.forEach((value, index) => {
        genderOptions.value.push({label: value.name, value: value.key})
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    })

    const onSubmit = () => {
      busy.value = true
      store.dispatch('store/' + (dataInfo.value.id > 0 ? 'editContact' : 'addContact'), {customerId: router.currentRoute.params.id, dataInfo: dataInfo.value}).then(response => {
        toastMessage(toast, 'success', response.data.message)
        emit('update:is-contact-new-sidebar-active', false)
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetRecordData)

    watch(() => props.isContactNewSidebarActive, (dataValue) => {
      if (dataValue) {
        if (props.recordDataId === 0) {
          resetForm()
        } else {
          busy.value = true
          store.dispatch('store/fetchContact', {customerId: router.currentRoute.params.id, id: props.recordDataId}).then(response => {
            dataInfo.value = response.data.data
          }).catch(error => {
            toastMessage(toast, 'danger', error.response.data.message)
            emit('update:is-contact-new-sidebar-active', false)
          }).finally(message => {
            busy.value = false
          })
        }
      } else {
        resetForm()
      }
    })

    return {
      busy,
      dataInfo,
      refFormObserver,
      genderOptions,

      statusOptions,

      getValidationState,
      resetForm,
      onSubmit,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#contact-new-record-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
