<template>
  <b-card>
    <b-tabs pills>
      <b-tab active>
        <template #title>
          <feather-icon icon="BookIcon"/>
          <span>Genel Bilgiler</span>
        </template>
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-form @submit.prevent="handleSubmit(onSubmit)">

            <b-row class="mt-1">
              <b-col cols="12" md="9" lg="9">
                <b-row>
                  <b-col cols="12" md="6" lg="6">
                    <validation-provider #default="validationContext" name="Müşteri Tipi" rules="required">
                      <b-form-group label="Müşteri Tipi" :state="getValidationState(validationContext)">
                        <v-select v-model="dataInfo.customerTypeId" :options="customerTypeOptions" :reduce="val => val.value" :clearable="false"/>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="6" lg="6">
                    <validation-provider #default="validationContext" name="Ünvan" rules="required">
                      <b-form-group label="Ünvan">
                        <b-form-input trim placeholder="Ünvan" v-model="dataInfo.name" :state="getValidationState(validationContext)"/>
                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="6" lg="6">
                    <validation-provider #default="validationContext" name="Ana Sektör" rules="required">
                      <b-form-group label="Ana Sektör" :state="getValidationState(validationContext)">
                        <v-select v-model="dataInfo.mainSectorId" :options="sectorOptions" :reduce="val => val.value" :clearable="true" v-on:input="getSectorList"/>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="6" lg="6">
                    <validation-provider #default="validationContext" name="Alt Sektör" rules="required">
                      <b-form-group label="Alt Sektör" :state="getValidationState(validationContext)">
                        <v-select v-model="dataInfo.subSectorId" :options="subSectorOptions" :reduce="val => val.value" :clearable="true"/>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="6" lg="6">
                    <validation-provider #default="validationContext" name="Müşteri Temsilcisi" rules="required">
                      <b-form-group label="Müşteri Temsilcisi" :state="getValidationState(validationContext)">
                        <v-select v-model="dataInfo.salesmanId" :options="salesmanOptions" :reduce="val => val.value" :clearable="true"/>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="6" lg="6">
                    <validation-provider #default="validationContext" name="Durum" rules="required">
                      <b-form-group label="Durum" :state="getValidationState(validationContext)">
                        <v-select v-model="dataInfo.isActive" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="6" lg="6">
                    <validation-provider #default="validationContext" name="Müşteri Ziyaret Sıklığı" rules="required">
                      <b-form-group label="Müşteri Ziyaret Sıklığı" :state="getValidationState(validationContext)">
                        <v-select v-model="dataInfo.visitPeriodId" :options="visitPeriodOptions" :reduce="val => val.value" :clearable="false"/>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="6" lg="6">
                    <validation-provider #default="validationContext" name="Ödeme Planı" rules="required">
                      <b-form-group label="Ödeme Planı" :state="getValidationState(validationContext)">
                        <v-select v-model="dataInfo.paymentTermDayId" :options="paymentTermDayOptions" :reduce="val => val.value" :clearable="false"/>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col cols="12" md="6" lg="6">
                    <validation-provider #default="validationContext" name="Para Birimi">
                      <b-form-group label="Para Birimi" :state="getValidationState(validationContext)">
                        <v-select v-model="dataInfo.currencyTypeId" :options="currencyTypeOptions" :reduce="val => val.value" :clearable="false"/>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col cols="12" md="6" lg="6">
                    <validation-provider #default="validationContext" name="Ödeme Şekli">
                      <b-form-group label="Ödeme Şekli" :state="getValidationState(validationContext)">
                        <v-select v-model="dataInfo.paymentMethodId" :options="paymentMethodOptions" :reduce="val => val.value" :clearable="false"/>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col cols="12" md="6" lg="6">
                    <validation-provider #default="validationContext" name="Kullanılabilir" rules="required">
                      <b-form-group label="Kullanılabilir" :state="getValidationState(validationContext)">
                        <v-select v-model="dataInfo.isAvailable" :options="yesNoOptions" :reduce="val => val.value" :clearable="false"/>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                </b-row>
              </b-col>
              <b-col cols="12" md="3" lg="3" class="d-flex flex-wrap justify-content-center align-content-center">
                <b-avatar
                    ref="previewEl"
                    :src="(dataInfo.companyLogoBase64 ? dataInfo.companyLogoBase64 : getApiFile(dataInfo.companyLogo))"
                    button
                    variant="primary"
                    size="90px"
                    @click="$refs.refInputEl.click()">
                  <feather-icon icon="BookIcon" size="30" v-if="dataInfo.companyLogoBase64 === null && dataInfo.companyLogo === ''"/>
                </b-avatar>
                <input
                    ref="refInputEl"
                    type="file"
                    class="d-none"
                    @input="inputImageRenderer">
              </b-col>
            </b-row>

            <div class="d-flex mt-1">
              <feather-icon icon="MapIcon" size="19"/>
              <h4 class="mb-0 ml-50">İletişim Bilgileri</h4>
            </div>

            <b-row class="mt-1">
              <b-col cols="12" md="9" lg="9">
                <b-row>
                  <b-col cols="12" md="6" lg="6">
                    <validation-provider #default="validationContext" name="Ülke" rules="required">
                      <b-form-group label="Ülke" :state="getValidationState(validationContext)">
                        <v-select v-model="dataInfo.countryId" :options="countryOptions" :reduce="val => val.value" :clearable="false" v-on:input="getCities"/>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="6" lg="6">
                    <validation-provider #default="validationContext" name="Şehir" rules="required">
                      <b-form-group label="Şehir" :state="getValidationState(validationContext)">
                        <v-select v-model="dataInfo.cityId" :options="cityOptions" :reduce="val => val.value" :clearable="true" v-on:input="getTowns"/>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="6" lg="6">
                    <validation-provider #default="validationContext" name="İlçe">
                      <b-form-group label="İlçe" :state="getValidationState(validationContext)">
                        <v-select v-model="dataInfo.townId" :options="townOptions" :reduce="val => val.value" :clearable="true" v-on:input="getDistrictVillages"/>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="6" lg="6">
                    <validation-provider #default="validationContext" name="Mahalle">
                      <b-form-group label="Mahalle" :state="getValidationState(validationContext)">
                        <v-select v-model="dataInfo.districtId" :options="districtOptions" :reduce="val => val.value" :clearable="true"/>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="6" lg="6">
                    <validation-provider #default="validationContext" name="Telefon">
                      <b-form-group label="Telefon">
                        <b-form-input trim placeholder="Telefon" v-model="dataInfo.phoneNumber" :state="getValidationState(validationContext)"/>
                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="6" lg="6">
                    <validation-provider #default="validationContext" name="Telefon 2">
                      <b-form-group label="Telefon 2">
                        <b-form-input trim placeholder="Telefon 2" v-model="dataInfo.phoneNumber2" :state="getValidationState(validationContext)"/>
                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="6" lg="6">
                    <b-row>
                      <b-col cols="12" md="12" lg="12">
                        <validation-provider #default="validationContext" name="Adres">
                          <b-form-group label="Adres">
                            <b-form-textarea trim placeholder="Adres" v-model="dataInfo.address" :state="getValidationState(validationContext)" rows="4"/>
                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="12" md="6" lg="6">
                    <b-row>
                      <b-col cols="12" md="12" lg="12">
                        <validation-provider #default="validationContext" name="Latitude">
                          <b-form-group label="Latitude">
                            <b-form-input trim placeholder="Latitude" v-model="dataInfo.latitude" :state="getValidationState(validationContext)"/>
                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col cols="12" md="12" lg="12">
                        <validation-provider #default="validationContext" name="Longitude">
                          <b-form-group label="Longitude">
                            <b-form-input trim placeholder="Longitude" v-model="dataInfo.longitude" :state="getValidationState(validationContext)"/>
                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12" md="3" lg="3">
              </b-col>
            </b-row>

            <div class="d-flex mt-1">
              <feather-icon icon="BookIcon" size="19"/>
              <h4 class="mb-0 ml-50">Ticari Bilgiler</h4>
            </div>

            <b-row class="mt-1">
              <b-col cols="12" md="9" lg="9">
                <b-row>
                  <b-col cols="12" md="6" lg="6">
                    <validation-provider #default="validationContext" name="Aylık Potansiyel Ciro">
                      <b-form-group label="Aylık Potansiyel Ciro">
                        <b-form-input trim placeholder="Aylık Potansiyel Ciro" v-model="dataInfo.potentialTurnover" :state="getValidationState(validationContext)"/>
                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="6" lg="6"></b-col>
                  <b-col cols="12" md="6" lg="6">
                    <validation-provider #default="validationContext" name="Vergi Dairesi">
                      <b-form-group label="Vergi Dairesi">
                        <b-form-input trim placeholder="Vergi Dairesi" v-model="dataInfo.taxOffice" :state="getValidationState(validationContext)"/>
                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="6" lg="6">
                    <validation-provider #default="validationContext" name="Vergi Numarası">
                      <b-form-group label="Vergi Numarası">
                        <b-form-input trim placeholder="Vergi Numarası" v-model="dataInfo.taxNumber" :state="getValidationState(validationContext)"/>
                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="12" lg="12">
                    <validation-provider #default="validationContext" name="Piyasa İstihbarat Bilgileri">
                      <b-form-group label="Piyasa İstihbarat Bilgileri">
                        <b-form-textarea trim placeholder="Piyasa İstihbarat Bilgileri" v-model="dataInfo.marketIntelligenceInformation" :state="getValidationState(validationContext)" rows="4"/>
                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="12" lg="12">
                    <validation-provider #default="validationContext" name="Kardeş Şirketler ve Yerleşim Yerleri">
                      <b-form-group label="Kardeş Şirketler ve Yerleşim Yerleri">
                        <b-form-textarea trim placeholder="Kardeş Şirketler ve Yerleşim Yerleri" v-model="dataInfo.brotherCompaniesAndLocations" :state="getValidationState(validationContext)" rows="4"/>
                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="12" lg="12">
                    <validation-provider #default="validationContext" name="Firma Sahibi ve Ortakları">
                      <b-form-group label="Firma Sahibi ve Ortakları">
                        <b-form-textarea trim placeholder="Firma Sahibi ve Ortakları" v-model="dataInfo.companyOwnerAndPartners" :state="getValidationState(validationContext)" rows="4"/>
                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12" md="3" lg="3">
              </b-col>
            </b-row>

            <action-buttons :back-route="'definition-customer-list'"/>

          </b-form>
        </validation-observer>
      </b-tab>
      <AddressList></AddressList>
      <ContactList></ContactList>
      <CapacityList></CapacityList>
    </b-tabs>
    <Overlay :busy="busy"></Overlay>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BTabs, BTab, BFormTextarea, BAvatar
} from 'bootstrap-vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import {onUnmounted, ref} from '@vue/composition-api'
import {useToast} from 'vue-toastification/composition'
import {toastMessage} from "@core/utils/utils";
import router from '@/router'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required, email} from '@validations'
import {avatarText, getApiFile, statusOptions, yesNoOptions} from "@core/utils/filter"
import Overlay from "@/components/Overlay.vue";
import ActionButtons from "@/components/Form/ActionButtons.vue";
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/definition/customer/store"
import definitionModule from "@/views/system/definition/store"
import sectorModule from "@/views/definition/sector/store"
import salesmanModule from "@/views/definition/salesman/store"
import addressModule from "@/store/common/address"
import {useInputImageRenderer} from "@core/comp-functions/forms/form-utils";
import AddressList from "@/views/definition/customer/address/List.vue";
import ContactList from "@/views/definition/customer/contact/List.vue";
import CapacityList from "@/views/definition/customer/capacity/List.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BTabs,
    BTab,
    BFormTextarea,
    BAvatar,

    ActionButtons,
    Overlay,
    vSelect,
    AddressList,
    ContactList,
    CapacityList,

    ValidationProvider,
    ValidationObserver,
  },
  props: {},
  data() {
    return {
      required,
      email,
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    const STORE_DEFINITION_MODULE_NAME = 'store-definition'
    const STORE_SECTOR_MODULE_NAME = 'store-sector'
    const STORE_SALESMAN_MODULE_NAME = 'store-salesman'
    const STORE_ADDRESS_MODULE_NAME = 'store-address'
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
      store.registerModule(STORE_DEFINITION_MODULE_NAME, definitionModule)
      store.registerModule(STORE_SECTOR_MODULE_NAME, sectorModule)
      store.registerModule(STORE_SALESMAN_MODULE_NAME, salesmanModule)
      store.registerModule(STORE_ADDRESS_MODULE_NAME, addressModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
        store.unregisterModule(STORE_DEFINITION_MODULE_NAME)
        store.unregisterModule(STORE_SECTOR_MODULE_NAME)
        store.unregisterModule(STORE_SALESMAN_MODULE_NAME)
        store.unregisterModule(STORE_ADDRESS_MODULE_NAME)
      }
    })

    const userData = JSON.parse(localStorage.getItem('userData'))

    const toast = useToast()
    const busy = ref(false)
    const dataInfo = ref({
      id: 0,
      code: '',
      name: '',
      customerTypeId: '',
      mainSectorId: null,
      subSectorId: null,
      salesmanId: (userData.userType === 1 ? userData.userTypeId : null),
      countryId: 1,
      cityId: null,
      townId: null,
      districtId: null,
      villageId: null,
      phoneNumber: '',
      phoneNumber2: '',
      address: '',
      latitude: '',
      longitude: '',
      taxOffice: '',
      taxNumber: '',
      companyLogo: '',
      companyLogoBase64: null,
      marketIntelligenceInformation: '',
      brotherCompaniesAndLocations: '',
      companyOwnerAndPartners: '',
      visitPeriodId: '',
      paymentTermDayId: '',
      currencyTypeId: '',
      paymentMethodId: '',
      potentialTurnover: '',
      isActive: true,
      isAvailable: true
    })

    const customerTypeOptions = ref([])
    const sectorOptions = ref([])
    const subSectorOptions = ref([])
    const salesmanOptions = ref([])
    const visitPeriodOptions = ref([])
    const paymentTermDayOptions = ref([])
    const countryOptions = ref([])
    const cityOptions = ref([])
    const townOptions = ref([])
    const districtOptions = ref([])
    const villageOptions = ref([])
    const currencyTypeOptions = ref([])
    const paymentMethodOptions = ref([])

    const onSubmit = () => {
      busy.value = true
      store.dispatch('store/' + (router.currentRoute.params.id > 0 ? 'editItem' : 'addItem'), dataInfo.value).then(response => {
        toastMessage(toast, 'success', response.data.message)
        router.push({name: 'definition-customer-list'})
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    const getCities = (val) => {
      cityOptions.value = []
      townOptions.value = []
      districtOptions.value = []
      villageOptions.value = []

      if (val != null) {
        busy.value = true
        store.dispatch('store-address/cityList', {country: val}).then(response => {
          response.data.data.forEach((value, index) => {
            cityOptions.value.push({label: value.name, value: value.id})
          });
        }).catch(error => {
          toastMessage(toast, 'danger', error.response.data.message)
        }).finally(message => {
          busy.value = false
        })
      } else {
        dataInfo.value.cityId = null
        dataInfo.value.townId = null
        dataInfo.value.districtId = null
        dataInfo.value.villageId = null
      }
    }

    const getTowns = (val) => {
      townOptions.value = []
      districtOptions.value = []
      villageOptions.value = []

      if (val != null) {
        busy.value = true
        store.dispatch('store-address/townList', {city: val}).then(response => {
          response.data.data.forEach((value, index) => {
            townOptions.value.push({label: value.name, value: value.id})
          });
        }).catch(error => {
          toastMessage(toast, 'danger', error.response.data.message)
        }).finally(message => {
          busy.value = false
        })
      } else {
        dataInfo.value.townId = null
        dataInfo.value.districtId = null
        dataInfo.value.villageId = null
      }
    }

    const getDistrictVillages = (val) => {
      districtOptions.value = []
      villageOptions.value = []

      if (val != null) {
        busy.value = true
        store.dispatch('store-address/districtList', {town: val}).then(response => {
          response.data.data.forEach((value, index) => {
            districtOptions.value.push({label: value.name, value: value.id})
          });
        }).catch(error => {
          toastMessage(toast, 'danger', error.response.data.message)
        }).finally(message => {
          busy.value = false
        })

      } else {
        dataInfo.value.districtId = null
        dataInfo.value.villageId = null
      }
    }

    const getSectorList = (parent) => {
      if (parent == null) {
        sectorOptions.value = []
      } else {
        subSectorOptions.value = []
      }

      busy.value = true
      store.dispatch('store-sector/fetchItems').then(response => {
        response.data.data.forEach((value, index) => {
          if (value.parentId === parent && value.isActive) {
            if (parent == null) {
              sectorOptions.value.push({label: value.name, value: value.id})
            } else {
              subSectorOptions.value.push({label: value.name, value: value.id})
            }
          }
        });
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    const previewEl = ref(null)
    const refInputEl = ref(null)
    const {inputImageRenderer} = useInputImageRenderer(refInputEl, base64 => {
      dataInfo.value.companyLogoBase64 = base64
    })

    busy.value = true
    store.dispatch('store-definition/definitionList', {groupKey: 'CUSTOMER_TYPE'}).then(response => {
      response.data.data.forEach((value, index) => {
        if (value.key !== 'REAL') {
          customerTypeOptions.value.push({label: value.name, value: value.key})
          if (value.isDefault && router.currentRoute.params.id === undefined) {
            dataInfo.value.customerTypeId = value.key
          }
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    busy.value = true
    store.dispatch('store-definition/definitionList', {groupKey: 'CUSTOMER_VISIT_PERIOD'}).then(response => {
      response.data.data.forEach((value, index) => {
        visitPeriodOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.visitPeriodId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    busy.value = true
    store.dispatch('store-definition/definitionList', {groupKey: 'CUSTOMER_PAYMENT_TERM_DAY'}).then(response => {
      response.data.data.forEach((value, index) => {
        paymentTermDayOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.paymentTermDayId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    busy.value = true
    store.dispatch('store-definition/definitionList', {groupKey: 'CURRENCY_TYPE'}).then(response => {
      response.data.data.forEach((value, index) => {
        currencyTypeOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.currencyTypeId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    busy.value = true
    store.dispatch('store-definition/definitionList', {groupKey: 'PAYMENT_METHOD'}).then(response => {
      response.data.data.forEach((value, index) => {
        paymentMethodOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.paymentMethodId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    busy.value = true
    store.dispatch('store-salesman/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          salesmanOptions.value.push({label: value.name, value: value.id})
          if (router.currentRoute.params.id === undefined) {
            dataInfo.value.salesmanId = value.id
          }
        }
      })

    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    busy.value = true
    store.dispatch('store-address/countryList').then(response => {
      response.data.data.forEach((value, index) => {
        countryOptions.value.push({label: value.name, value: value.id})
      });

      if (router.currentRoute.params.id === undefined) {
        getCities(dataInfo.value.countryId)
      }

    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    if (router.currentRoute.params.id > 0) {
      //get customer
      busy.value = true
      store.dispatch('store/fetchItem', {id: router.currentRoute.params.id}).then(response => {
        dataInfo.value = response.data.data

        getCities(dataInfo.value.countryId)

        if (dataInfo.value.cityId > 0) {
          getTowns(dataInfo.value.cityId)
        }
        if (dataInfo.value.townId > 0) {
          getDistrictVillages(dataInfo.value.townId)
        }
        if (dataInfo.value.mainSectorId > 0) {
          getSectorList(dataInfo.value.mainSectorId)
        }

      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
        if (error.response.status === 403) {
          router.push({name: 'definition-customer-list'})
        }
      }).finally(message => {
        busy.value = false
      })
    }

    getSectorList(null)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      busy,
      dataInfo,
      refFormObserver,

      customerTypeOptions,
      sectorOptions,
      subSectorOptions,
      salesmanOptions,
      countryOptions,
      cityOptions,
      townOptions,
      districtOptions,
      villageOptions,
      visitPeriodOptions,
      paymentTermDayOptions,
      currencyTypeOptions,
      paymentMethodOptions,

      previewEl,
      refInputEl,

      statusOptions,
      yesNoOptions,

      onSubmit,
      getValidationState,
      resetForm,

      getCities,
      getTowns,
      getDistrictVillages,
      getSectorList,
      inputImageRenderer,
      getApiFile,
      avatarText,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>