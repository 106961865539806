<template>
  <b-tab v-if="$router.currentRoute.params.id > 0 && isVisible">
    <template #title>
      <feather-icon icon="MapIcon"/>
      <span>Adresler</span>
    </template>
    <b-card no-body class="border mt-1 mb-0">
      <div class="m-1">
        <b-row>
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <v-select v-model="perPage" class="per-page-selector d-inline-block mx-50" :options="perPageOptions" :clearable="false"/>
          </b-col>
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Arama..."/>
              <AddressDetail :is-address-new-sidebar-active.sync="isAddressNewSidebarActive" :record-data-id="addressRecordDataId"></AddressDetail>
              <b-button-toolbar justify>
                <b-button-group>
                  <b-button @click="reFetchData" variant="outline-primary" size="md">
                    <feather-icon icon="RefreshCcwIcon" size="18"/>
                    <span class="align-middle"></span>
                  </b-button>
                  <b-button @click="isAddressNewSidebarActive = true; addressRecordDataId=0" variant="primary" size="md">
                    <feather-icon icon="PlusCircleIcon" size="18"/>
                    <span class="align-middle"></span>
                  </b-button>
                </b-button-group>
              </b-button-toolbar>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
          ref="refListTable"
          primary-key="id"
          empty-text="Kayıt bulunamadı"
          :class="`position-relative`"
          :items="fetchList"
          :fields="tableColumns"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          responsive striped bordered hover show-empty>

        <template #cell(id)="data">
          <b-link @click="changeAddress(data.item)" class="font-weight-bold">#{{ data.value }}</b-link>
        </template>

        <template #cell(isActive)="data">
          <active-column-table :data="data.value"></active-column-table>
        </template>
        <template #cell(createdOn)="data">
          <date-column-table :data="data.value"></date-column-table>
        </template>

        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon :id="`row-${data.item.id}-prev`" icon="EyeIcon" size="16" @click="previewRecord(data.item)" class="mx-1"/>
            <b-tooltip title="Görüntüle" class="cursor-pointer" :target="`row-${data.item.id}-prev`"/>
            <feather-icon :id="`row-${data.item.id}-edit`" icon="EditIcon" size="16" class="mr-1" @click="changeAddress(data.item)"/>
            <b-tooltip title="Değiştir" class="cursor-pointer" :target="`row-${data.item.id}-edit`"/>
            <feather-icon :id="`row-${data.item.id}-remove`" icon="TrashIcon" size="16" @click="removeAddress(data.item)"/>
            <b-tooltip title="Çıkar" class="cursor-pointer" :target="`row-${data.item.id}-remove`"/>
          </div>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <footer-table :data-meta="dataMeta"></footer-table>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" v-model="currentPage" :total-rows="totalCount" :per-page="perPage" first-number last-number>

              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18"/>
              </template>

              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18"/>
              </template>

            </b-pagination>
          </b-col>
        </b-row>
      </div>

      <Overlay :busy="busy"></Overlay>
      <preview :is-preview-modal.sync="isPreviewModal" :data-record.sync="dataPreviewRecord"></preview>
    </b-card>
  </b-tab>
</template>

<script>
import {BCard, BRow, BCol, BFormInput, BTable, BPagination, BTooltip, BTab, BButtonToolbar, BButtonGroup, BButton, BLink} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Overlay from "@/components/Overlay.vue";
import {ref, watch} from '@vue/composition-api'
import useList from './useList'
import Preview from "@/views/definition/customer/address/Preview.vue";
import AddressDetail from "@/views/definition/customer/address/Detail.vue";
import Vue from "vue";
import store from "@/store";
import router from "@/router";
import {ActiveColumnTable, DateColumnTable, FooterTable} from "@/components/Table"

export default {
  name: "AddressList",
  components: {
    BLink,
    BButton,
    BButtonGroup,
    BButtonToolbar,
    BCard, BRow, BCol, BFormInput, BTable, BPagination, BTooltip, BTab,
    Overlay, Preview, FooterTable, vSelect, AddressDetail, DateColumnTable, ActiveColumnTable
  },
  setup() {

    const {
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      busy,
      isVisible,

      fetchList,
      reFetchData,
    } = useList()

    const dataPreviewRecord = ref({})
    const isPreviewModal = ref(false)
    const previewRecord = (dataItem) => {
      dataPreviewRecord.value = dataItem
      isPreviewModal.value = true
    }

    const isAddressNewSidebarActive = ref(false)
    const addressRecordDataId = ref(0)

    const changeAddress = (dataItem) => {
      addressRecordDataId.value = dataItem.id
      isAddressNewSidebarActive.value = true
    }

    const removeAddress = (dataItem) => {
      Vue.swal({
        title: 'Onaylıyor musunuz?',
        text: 'Kayıt çıkarılacaktır!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet, Çıkar',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('store/removeAddress', {customerId: router.currentRoute.params.id, id: dataItem.id}).then(response => {
            if (response.data.success) {
              reFetchData()
              Vue.swal({
                icon: 'success',
                title: 'Başarılı!',
                text: 'Kayıt çıkarıldı.',
                confirmButtonText: 'Tamam',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              })
            }
          }).catch(error => {
            Vue.swal({
              icon: 'error',
              title: 'Hata!',
              text: error.response.data.message,
              confirmButtonText: 'Tamam',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })
        }
      })
    }

    watch(isAddressNewSidebarActive, (dataValue) => {
      if (dataValue === false) {
        reFetchData()
      }
    })

    return {
      busy,
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      isPreviewModal,
      dataPreviewRecord,
      isVisible,

      isAddressNewSidebarActive,
      addressRecordDataId,

      fetchList,
      reFetchData,
      previewRecord,

      changeAddress,
      removeAddress,
    }
  }
}
</script>