import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        countryList(ctx) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/address/countries')
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        cityList(ctx, {country}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/address/cities/${country}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        townList(ctx, {city}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/address/towns/${city}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        districtList(ctx, {town}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/address/districts/${town}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        villageList(ctx, {town}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/address/villages/${town}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
