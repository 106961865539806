<template>
  <b-sidebar
      no-close-on-backdrop
      shadow
      no-header
      right
      backdrop
      bg-variant="white"
      sidebar-class="sidebar-lg"
      id="address-new-record-sidebar"
      :backdrop-variant="'dark'"
      :visible="isAddressNewSidebarActive">
    <template #default="{ hide }">

      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">{{ dataInfo.id === 0 ? 'Adres Ekle' : 'Adres Değiştir' }}</h5>
      </div>

      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">

          <validation-provider #default="validationContext" name="Adres Tipi" rules="required">
            <b-form-group label="Adres Tipi" :state="getValidationState(validationContext)">
              <v-select v-model="dataInfo.addressTypeId" :options="addressTypeOptions" :reduce="val => val.value" :clearable="true"/>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Adres Adı" rules="required">
            <b-form-group label="Adres Adı">
              <b-form-input trim placeholder="Adres Adı" v-model="dataInfo.name" :state="getValidationState(validationContext)"/>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Ülke" rules="required">
            <b-form-group label="Ülke" :state="getValidationState(validationContext)">
              <v-select v-model="dataInfo.countryId" :options="countryOptions" :reduce="val => val.value" :clearable="false" v-on:input="getCities"/>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Şehir" rules="required">
            <b-form-group label="Şehir" :state="getValidationState(validationContext)">
              <v-select v-model="dataInfo.cityId" :options="cityOptions" :reduce="val => val.value" :clearable="true" v-on:input="getTowns"/>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="İlçe">
            <b-form-group label="İlçe" :state="getValidationState(validationContext)">
              <v-select v-model="dataInfo.townId" :options="townOptions" :reduce="val => val.value" :clearable="true" v-on:input="getDistrictVillages"/>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Mahalle">
            <b-form-group label="Mahalle" :state="getValidationState(validationContext)">
              <v-select v-model="dataInfo.districtId" :options="districtOptions" :reduce="val => val.value" :clearable="true"/>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

<!--          <validation-provider #default="validationContext" name="Köy">-->
<!--            <b-form-group label="Köy" :state="getValidationState(validationContext)">-->
<!--              <v-select v-model="dataInfo.villageId" :options="villageOptions" :reduce="val => val.value" :clearable="true"/>-->
<!--              <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>-->
<!--            </b-form-group>-->
<!--          </validation-provider>-->

          <validation-provider #default="validationContext" name="Telefon">
            <b-form-group label="Telefon">
              <b-form-input trim placeholder="Telefon" v-model="dataInfo.phoneNumber" :state="getValidationState(validationContext)"/>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Telefon 2">
            <b-form-group label="Telefon 2">
              <b-form-input trim placeholder="Telefon 2" v-model="dataInfo.phoneNumber2" :state="getValidationState(validationContext)"/>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Adres">
            <b-form-group label="Adres">
              <b-form-textarea trim placeholder="Adres" v-model="dataInfo.address" :state="getValidationState(validationContext)"/>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Latitude">
            <b-form-group label="Latitude">
              <b-form-input trim placeholder="Latitude" v-model="dataInfo.latitude" :state="getValidationState(validationContext)"/>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Longitude">
            <b-form-group label="Longitude">
              <b-form-input trim placeholder="Longitude" v-model="dataInfo.longitude" :state="getValidationState(validationContext)"/>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Durum" rules="required">
            <b-form-group label="Durum" label-for="isActive" :state="getValidationState(validationContext)">
              <v-select v-model="dataInfo.isActive" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div class="d-flex mt-2">
            <b-button variant="primary" class="mr-2" type="submit">Kaydet</b-button>
            <b-button type="button" variant="outline-secondary" @click="$emit('update:is-address-new-sidebar-active', false)">Vazgeç</b-button>
          </div>

        </b-form>
      </validation-observer>

      <Overlay :busy="busy"></Overlay>

    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {ref, watch} from '@vue/composition-api'
import {required} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import {statusOptions} from "@core/utils/filter"
import store from "@/store";
import {toastMessage} from "@core/utils/utils";
import {useToast} from "vue-toastification/composition";
import Overlay from "@/components/Overlay.vue";
import router from "@/router";

export default {
  components: {
    BFormTextarea,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,

    vSelect,
    Overlay,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  model: {
    prop: 'isAddressNewSidebarActive',
    event: 'update:is-address-new-sidebar-active',
  },
  props: {
    isAddressNewSidebarActive: {
      type: Boolean,
      required: true,
    },
    recordDataId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, {emit}) {
    const toast = useToast()
    const busy = ref(false)

    const blankRecordData = {
      id: 0,
      addressTypeId: null,
      name: '',
      countryId: 1,
      cityId: null,
      townId: null,
      districtId: null,
      villageId: null,
      phoneNumber: '',
      phoneNumber2: '',
      address: '',
      latitude: '',
      longitude: '',
      isActive: true,
    }
    const dataInfo = ref(JSON.parse(JSON.stringify(blankRecordData)))
    const resetRecordData = () => {
      dataInfo.value = JSON.parse(JSON.stringify(blankRecordData))
    }

    const countryOptions = ref([])
    const cityOptions = ref([])
    const townOptions = ref([])
    const districtOptions = ref([])
    const villageOptions = ref([])
    const addressTypeOptions = ref([])

    store.dispatch('store-address/countryList').then(response => {
      response.data.data.forEach((value, index) => {
        countryOptions.value.push({label: value.name, value: value.id})
      });
      if (dataInfo.value.id === 0) {
        getCities(dataInfo.value.countryId)
      }
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    })

    store.dispatch('store-definition/definitionList', {groupKey: 'ADDRESS_TYPE'}).then(response => {
      response.data.data.forEach((value, index) => {
        addressTypeOptions.value.push({label: value.name, value: value.key})
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    })

    const onSubmit = () => {
      busy.value = true
      store.dispatch('store/' + (dataInfo.value.id > 0 ? 'editAddress' : 'addAddress'), {customerId: router.currentRoute.params.id, dataInfo: dataInfo.value}).then(response => {
        toastMessage(toast, 'success', response.data.message)
        emit('update:is-address-new-sidebar-active', false)
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    const getCities = (val) => {
      cityOptions.value = []
      townOptions.value = []
      districtOptions.value = []
      villageOptions.value = []

      if (val != null) {
        store.dispatch('store-address/cityList', {country: val}).then(response => {
          response.data.data.forEach((value, index) => {
            cityOptions.value.push({label: value.name, value: value.id})
          });
        }).catch(error => {
          toastMessage(toast, 'danger', error.response.data.message)
        })
      } else {
        dataInfo.value.cityId = null
        dataInfo.value.townId = null
        dataInfo.value.districtId = null
        dataInfo.value.villageId = null
      }
    }

    const getTowns = (val) => {
      townOptions.value = []
      districtOptions.value = []
      villageOptions.value = []

      if (val != null) {
        store.dispatch('store-address/townList', {city: val}).then(response => {
          response.data.data.forEach((value, index) => {
            townOptions.value.push({label: value.name, value: value.id})
          });
        }).catch(error => {
          toastMessage(toast, 'danger', error.response.data.message)
        })
      } else {
        dataInfo.value.townId = null
        dataInfo.value.districtId = null
        dataInfo.value.villageId = null
      }
    }

    const getDistrictVillages = (val) => {
      districtOptions.value = []
      villageOptions.value = []

      if (val != null) {
        store.dispatch('store-address/districtList', {town: val}).then(response => {
          response.data.data.forEach((value, index) => {
            districtOptions.value.push({label: value.name, value: value.id})
          });
        }).catch(error => {
          toastMessage(toast, 'danger', error.response.data.message)
        })

        store.dispatch('store-address/villageList', {town: val}).then(response => {
          response.data.data.forEach((value, index) => {
            villageOptions.value.push({label: value.name, value: value.id})
          });
        }).catch(error => {
          toastMessage(toast, 'danger', error.response.data.message)
        })
      } else {
        dataInfo.value.districtId = null
        dataInfo.value.villageId = null
      }
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetRecordData)

    watch(() => props.isAddressNewSidebarActive, (dataValue) => {
      if (dataValue) {
        if (props.recordDataId === 0) {
          resetForm()
        } else {
          busy.value = true
          store.dispatch('store/fetchAddress', {customerId: router.currentRoute.params.id, id: props.recordDataId}).then(response => {
            dataInfo.value = response.data.data
            getCities(dataInfo.value.countryId)
            if (dataInfo.value.cityId > 0) {
              getTowns(dataInfo.value.cityId)
            }
            if (dataInfo.value.townId > 0) {
              getDistrictVillages(dataInfo.value.townId)
            }
          }).catch(error => {
            toastMessage(toast, 'danger', error.response.data.message)
            emit('update:is-address-new-sidebar-active', false)
          }).finally(message => {
            busy.value = false
          })
        }
      } else {
        resetForm()
      }
    })

    return {
      busy,
      dataInfo,
      refFormObserver,
      addressTypeOptions,
      countryOptions,
      cityOptions,
      townOptions,
      districtOptions,
      villageOptions,

      statusOptions,

      getValidationState,
      resetForm,
      onSubmit,

      getCities,
      getTowns,
      getDistrictVillages,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#address-new-record-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
